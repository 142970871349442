<template>
	<!-- <div id="app"> -->
	<v-app id="app" style="background: #aaaaaa;">
		<LeftNavigationAppBar v-if="business.ui == 1" />
		<TopCenterAppBar v-if="business.ui == 2" />

		<v-speed-dial absolute bottom fixed right v-model="fabWA" direction="left" transition="slide-x-reverse-transition"
			:open-on-hover="!$vuetify.breakpoint.mobile">
			<template v-slot:activator>
				<v-btn v-model="fabWA" color="#4FCE5D" dark depressed fab small aria-label="Contáctenos por whatsapp">
					<v-icon>mdi-whatsapp</v-icon>
				</v-btn>
			</template>
			<v-btn fab dark x-small color="indigo" rel="noreferrer" target="_blank" @click="contactWA(1)">
				<v-icon>mdi-home-account</v-icon>
			</v-btn>
			<v-btn
				fab
				dark
				x-small
				color="green darken-1"
				rel="noreferrer"
				target="_blank"
				v-if="account && account.sellers && account.sellers[0]?.phone != null"
				@click="contactWA(2)">
				<v-icon>mdi-account-tie</v-icon>
			</v-btn>
		</v-speed-dial>

		<v-snackbar timeout="3000" rounded="lg" v-model="snackBar.show" :color="snackBar.color">
			{{ snackBar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn depressed icon :color="`${snackBar.color} darken-2`" v-bind="attrs" @click="snackBar.show = false">
					<v-icon :color="`${snackBar.color} darken-2`">mdi-close-circle</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<v-footer app dark padless inset fixed>
			<v-card class="flex" flat tile>
				<v-card-text class="py-1 white--text text-center">
					&copy;{{ new Date().getFullYear() }} | <strong>{{ business.name }}</strong> | v{{ appVersion.versionNumber }} | <a href="https://www.codeflex.com.ar" target="_blank">by codeflex</a>
				</v-card-text>
			</v-card>
		</v-footer>

		<v-dialog v-model="dlgSelectBranch" persistent max-width="400">
			<v-card>
				<v-card-title class="primary white--text">SELECCIONÁ TU SUCURSAL</v-card-title>
				<v-card-text>
					Hemos detectado que tu comercio posee sucursales.<br />
					Indicanos en cuál sucursal estás usando la APP ahora, para que los pedidos que cargues en este dispositivo, sean
					entregados en el domicilio correcto.<br />
					Para tu tranquilidad, en el menú Mis Pedidos, el usuario Administrador podrá ver los pedidos de todas las
					sucursales.
					<v-row>
						<v-col>
							<v-autocomplete
								return-object
								flat
								rounded
								hide-details
								auto-select-first
								label="Sucursal por defecto"
								item-text="name"
								item-value="id"
								class="ml-0 mr-2"
								v-model="selectedBranch"
								:items="account.branches"></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<DialogSurvey />

		<v-snackbar top v-model="showOnlineAlert" timeout="-1" color="red" rounded="pill">
			No tenés conexión a internet.
		</v-snackbar>
	</v-app>
	<!-- </div> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import md5 from "md5";
import utils from "@/mixins/mixins";
import axios from "axios";
import business from "./store/business";

export default {
	name: "App",
	mixins: [utils],
	components: {
		LeftNavigationAppBar: () => import("@/views/menu/LeftNavigationAppBar.vue"),
		TopCenterAppBar: () => import("@/views/menu/TopCenterAppBar.vue"),
		DialogSurvey: () => import("@/components/DialogSurvey.vue"),
	},

	data: () => ({
		fabWA: false,
		drawer: null,
		dlgSelectBranch: false,
		refreshing: false,
		registration: null,
		selectedBranch: undefined,
		updateExists: false,
		polling: null,
		pollingInterval: 60 * 60000, // minutos * milisegundos por minuto
		// pollingInterval: 2 * 60000, // --> PARA TESTING  // minutos * milisegundos
		isOnline: navigator.onLine,
		showOnlineAlert: false,
	}),

	async created() {
		// WARNING: Habilitar lo siguiente cuando pongamos el cobro por MercadoPago
		await this.getParametersValues(2);
		
		// console.log('this.$store.state.business.shipmentAmount',this.$store.sstate.business.shipmentAmount);

		// Listen for swUpdated event and display refresh snackbar as required.
		document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
		// Refresh all open app tabs when a new service worker is installed.
		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (this.refreshing) return;
			this.refreshing = true;
			window.location.reload();
		});

		// if ( ("geolocation" in navigator) ) { // Veamos si soporta geolocation
		// 	navigator.geolocation.getCurrentPosition(pos => {
		// 		this.setGeoLocation( { latitude: pos.coords.latitude, longitude: pos.coords.longitude } )
		// 	}, err => { // this.gettingLocation = false;
		// 		console.log('No pude ubicarme.', err.message);
		// 	})
		// }

		// http://localhost:8080/home?cmd=remotelogin&code=120

		if (Object.keys(this.$route.query).length > 0 && !this.isAuthenticated) {
			const collectData = this.$route.query;
			if (!this.token && collectData.cmd.toLowerCase() === "remotelogin" && this.hashcode) {
				await this.remoteLogin(collectData.code);
			}
		}

		if (!this.isAuthenticated && this.business.isGuestEnabled) {
			let loginData = { username: "invitado", password: md5("invitado"), hashcode: "guest" };
			const log = await this.logIn(loginData);
			if (log) {
			} else {
				console.log("LOGIN error for guest user");
			}
		} else {
			this.readStorage();
			// this.checkAliveAndReconfig();
		}
		window.addEventListener("online", () => {
			this.showOnlineAlert = false;
		});
		window.addEventListener("offline", () => {
			this.showOnlineAlert = true;
		});
	},

	beforeDestroy() {
		window.removeEventListener("online", () => {
			this.showOnlineAlert = false;
		});
		window.removeEventListener("offline", () => {
			this.showOnlineAlert = true;
		});

		// updateWorker.terminate();

		clearInterval(this.polling);
		console.log(this.curDateTime() + " ** Updates stopped **");
	},

	methods: {
		...mapActions(["logIn", "remoteLogin", "readStorage", "getParametersValues", "setGeoLocation", "checkAliveAndReconfig", "getSurveys"]),

		showRefreshUI(e) {
			this.registration = e.detail;
			this.registration.waiting.postMessage("skipWaiting");
		},

		checkSurveys() {
			if (state.business.enableSurveys) {
				// Si las encuentas están habilitadas, traigo la que esté.
				this.getSurveys().then((res) => {
					if (res.data) {
						// if (this.getters.isDeveloping) { console.log("res.data --> ", res.data[0]); }
						// if (res.data && res.data[0].items)
						this.$store.commit("setState", {
							field: "surveyData",
							data: res.data && res.data.length > 0 ? res.data : null,
						});
						// if (this.getters.isDeveloping) { console.log("TENGO SURVEYS: ", state.surveyData); }
						if (state.surveyData !== null) {
							const timeout = this.getters.isDeveloping ? 5000 : Math.floor(Math.random() * 30) * 60000;
							// let interval = Math.floor(Math.random() * 30) * 60000
							// if (this.getters.isDeveloping) { console.log(dayjs().format("YYYY-MM-DD HH:mm:ss") + " Encuestra programada para dentro de ", timeout / 60000, " minutos"); }
							setTimeout(() => {
								this.$store.commit("setState", {
									field: "showSurvey",
									data: true,
								});
								// if (this.getters.isDeveloping) { console.log(dayjs().format("YYYY-MM-DD HH:mm:ss") + " Muestro el diálogo de ENCUESTA!"); }
							}, timeout);
						}
					}
				});
			}
		},

		contactWA(who) {
			let link, phone, businessName;
			link = document.createElement("a");
			if (who == 1) {
				phone = this.business.whatsapp;
				businessName = this.business.name;
			} else {
				phone = this.account.sellers[0]?.phone || this.business.whatsapp;
				businessName = this.account.sellers[0]?.full_name || '';
			}
			link.href = `https://api.whatsapp.com/send?phone=${phone}&text=Hola ${businessName}!. Escribo de *${this.account.name}* cuenta ${this.account.code}, `;
			link.setAttribute("target", "_blank");
			document.body.appendChild(link);
			link.click();
		},

		getApplicationsByPatent(patent) {
			let cualesCodigos = [];
			axios.get(`https://catalogofram.com.ar/resultado?term=${patent}&tipo=patente`)
				.then((res) => {
					const parser = new DOMParser(), dom = parser.parseFromString(res.data, "text/html");
					let myDivContent = dom.querySelectorAll(".code");
					myDivContent.forEach((item) => {
						cualesCodigos.push(item.innerText);
					});
				})
				.catch((err) => {
					console.log("ERROR: ", err);
				});

			axios.post("/articulo/read", {
				token: this.token,
				customerId: this.account.id,
				target: "listForFram",
				codes: this.cualesCodigos,
			})
				.then((res) => {
					// console.log(res.data);
				})
				.catch((err) => { });
		},
	},

	computed: {
		...mapState(["account", "business", "snackBar", "appVersion", "hashcode"]),
		...mapGetters(["isAuthenticated"]),
	},
	
};
</script>
